export const EXTRA_FEES = 'Extra Fees';
export const EXTRA_FEES_APPROVED = 'Approved';
export const EXTRA_FEES_REJECTED = 'Rejected';
export const EXTRA_FEES_SUBMITTED = 'Submitted';
export const EXTRA_FEES_UNDER_REVIEW = 'Under Review';
export const EXTRA_FEES_READY_FOR_APPROVAL = 'Ready for final approval';
export const EXTRA_FEES_ADDITIONAL_APPROVAL_NEEDED = 'Additional approval needed';

export const EXTRA_FEE_FINAL_APPROVAL_STATUSES = [
  EXTRA_FEES_ADDITIONAL_APPROVAL_NEEDED,
  EXTRA_FEES_READY_FOR_APPROVAL,
];

export const EXTRA_FEE_REVIEW_STATUSES = [
  EXTRA_FEES_SUBMITTED,
  EXTRA_FEES_UNDER_REVIEW,
  EXTRA_FEES_READY_FOR_APPROVAL,
  EXTRA_FEES_ADDITIONAL_APPROVAL_NEEDED,
];

export const EXTRA_FEE_STATUS_CHOICES = [
  EXTRA_FEES_APPROVED,
  EXTRA_FEES_REJECTED,
  EXTRA_FEES_SUBMITTED,
  EXTRA_FEES_UNDER_REVIEW,
  EXTRA_FEES_READY_FOR_APPROVAL,
  EXTRA_FEES_ADDITIONAL_APPROVAL_NEEDED,
];
export const BILL_FREQUENCY_CHOICES = [
  { value: 'all_work_complete', displayText: 'After all work completed' },
  { value: '10_hours', displayText: 'After every 10 hours of work completed' },
  { value: '15_hours', displayText: 'After every 15 hours of work completed' },
  { value: '20_hours', displayText: 'After every 20 hours of work completed' },
];
export const EXTRA_FEE_EMAIL_CHOICES = [
  { value: 'tiacobacci@legalplans.com', displayName: 'Taylor Iacobacci' },
  { value: 'jnodzak@legalplans.com', displayName: 'Jeff Nodzak' },
  { value: 'cgodlewski@legalplans.com', displayName: 'Claire Godlewski' },
  { value: 'relder@legalplans.com', displayName: 'Roger Elder' },
];

export const EXCLUDED_SERVICE_TYPES_FOR_EXTRA_FEES = [16, 17, 55];
